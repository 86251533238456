@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Nimbus Sans';
    src: local('Nimbus Sans'), url('./fonts/NimbusSanL-Reg.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: 'Nimbus Sans';
    src: local('Nimbus Sans'), url('./fonts/NimbusSanL-Bol.otf') format('opentype');
    font-style: normal;
    font-weight: bold;
}

* {
    font-family: "Nimbus Sans";
    color: #5F584B;
}

/* Basic App CSS */
html {
    background-color: #fffaf6;
    /* have to add this here because the scroll bar is on the html element */
    scroll-snap-type: y mandatory; 
}